import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import XperienceForm from "../components/Reusables/XperienceForm";
import img1 from "../images/pageImages/xperience-sign-up/image.png";
import img2 from "../images/pageImages/xperience-sign-up/Xperience.jpeg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const StyledWrapper = styled.div`
  .container {
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: -4px 5px 42px 149px rgba(7, 8, 8, 0.17);
    -webkit-box-shadow: -4px 5px 42px 149px rgba(7, 8, 8, 0.17);
    -moz-box-shadow: -4px 5px 42px 149px rgba(7, 8, 8, 0.17);
    @media (max-width: 600px) {
      width: 95%;
      padding-top: 8%;
      padding-bottom: 8%;
    }
  }
  .form-box {
    width: 80%;
    margin: 0 auto;
    padding: 1rem;
  }
  .form-label {
    font-weight: 500;
    font-size: 21px;
  }
  .text-label {
    font-weight: 500;
    font-size: 21px;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .form-control {
    height: 69px;
    width: 90%;
    background-color: #ececec;
    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }
  .sub-header {
    margin-top: 5rem;
  }
  .subtext {
    color: grey;
    margin-bottom: 1rem;
  }
  .text-muted {
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  .donation-img {
    height: 30rem;
    width: 60rem;
    margin: 0 auto;
    @media (max-width: 600px) {
      height: 10rem;
      width: 100%;
    }
  }
  .form-textarea {
    height: 10rem;
  }
  .submit {
    margin: 0 auto;
    width: 7rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .required-text {
    color: #e43d5a;
  }
  .img-logo {
    margin: 0 auto !important;
    height: 11rem;
    display: flex;
    align-item: center;
  }
  .response-box {
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: -3px 3px 39px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -3px 3px 39px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -3px 3px 39px 0px rgba(0, 0, 0, 0.75);
  }
  .response-logo {
    display: flex;
    margin: 0 auto;
    height: 9rem;
  }
  .response-header {
    display: block;
    margin-top: 1rem;
    text-align: center;
  }
  .response-text {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
`;
const ExperienceForm = () => {
  const { register, handleSubmit } = useForm();
  const [work, setWork] = useState();
  const [error, setError] = useState("");
  const [state, setState] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [noOfLocations, setNoOfLocations] = useState("");

  const handleOnChange = e => {
    setWork(e.target.value);
  };
  const submitForm = data => {
    const modifiedData = { ...data, locations: noOfLocations };
    if (modifiedData["sector"] === "null") {
      setError("Please select your sector");
      return;
    }
    if (modifiedData["locations"] === "") {
      setError("Please select your location");
      return;
    }
    if (modifiedData["noOfPlacements"] === "null") {
      setError("Please select number of placements");
      return;
    }
    if (modifiedData["donationSecondary"] === "null") {
      setError("Please select your donation options you are able to offer");
      return;
    }
    if (modifiedData["termsAndConditions"] === false) {
      setError(
        "Please agree to our terms and conditions and code of conduct by ticking the box above. If you do not wish to do so, please contact Hannah.Boyne@upreach.org.uk to express your interest"
      );
      return;
    }
    fetch(`https://formspree.io/f/mknynedd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(modifiedData),
    })
      .then(function (response) {
        if (response.status == 200) {
          setDisableBtn(true);
          notify();
          setError("");
          setState(true);
        } else {
          return response.json().then(data => setError(data.message));
        }
      })
      .catch(err => {
        console.log(err);
        setDisableBtn(false);
      });
  };

  const notify = () => {
    toast.info("Thank you. We will be in touch shortly with next steps", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const workAreas = [
    "Audit, Risk and Tax",
    "Charity and Not For Profit",
    "Communications",
    "Construction, Housing and Property",
    "Data Science and Analytics",
    "Engineering ",
    "Financial Advisory",
    "Government and Public Sector ",
    " Healthcare Management",
    " Human Resources ",
    " Insurance ",
    " Investment Banking ",
    " Investment Management",
    " Law ",
    "Logistics and Transportation Management",
    " Management Consultancy",
    " Manufacturing",
    " Marketing and Advertising",
    "Media and Arts ",
    "Project Management ",
    "Publishing ",
    " Retail and Commercial Banking",
    " Retail and Consumer Goods ",
    " Science and Pharmaceuticals ",
    " Technology",
    " Other ",
  ];
  const locations = [
    "North East",
    "North West",
    "Yorkshire and the Humber ",
    "East Midlands",
    "West Midlands",
    "East of England",
    "Greater London ",
    "South East",
    "South West ",
    "Wales",
    "Scotland",
  ];
  const placements = ["1-2", " 3-5", "6-8", "9-10", "11+"];
  const donations = [
    // "Help us fund participant and upReach costs",
    // "Help us with the above plus provide additional funding towards our charitable objectives",
    // "Help fund a proportion of participant and upReach costs",
    // "We're unable to make a donation",
    // "We will contact you to confirm in due course",
    "We're able to make the donation outlined above",
    "We'd like to speak to you about the donation element",
  ];
  const sectionTwo = {
    head: "Work Experience - Confirmed 4th-8th July",
    error: error,
    subhead:
      "We're delighted that you're able to offer work experience to our associates between 10th-14th July 2022. We're keen to find out more about the opportunities you can provide.",
    work: work,
    workExperienceAreas: {
      header:
        "Which area are you able to provide work experience in?  Please select the area that best aligns to the work experience placement you are able to offer, this may differ from your organisations primary sector. If this is not yet know please move on to the next question.",
      opt: workAreas,
    },
    workExperienceAreasSecondary: {
      header:
        "If you are able to provide work experience in more than one area please select the secondary area you're able to offer a placement in.",
      opt: workAreas,
    },
    location: {
      text:
        "In which location(s) are you able to offer placements? (We are keen to offer placements across the country so welcome opportunities in London but also outside of London and the South East) ",
      place: locations,
    },
    placements: {
      text: "Please confirm how many placements you are able to offer ",
      range: placements,
    },
    donation: {
      text:
        "Suggested Donation - We are asking employers to consider making a donation to cover participant costs (e.g. professional clothing bursary, reasonable travel and accommodation costs and a completion bursary) and upReach’s costs in delivering the programme. The suggested donations are outlined below. We appreciate that some organisations (for example charities or small businesses) may not be able to make a donation and we don’t want this to be a barrier. For further details of how we use the donations to fund the work experience places click here",
      img: img1,
    },
    donationSecondary: {
      text:
        "​​Please confirm which of the above suggested donation options you are able to offer.",
      options: donations,
    },
    comment: {
      text: "If you have any comments or questions please include them here. ",
    },
  };

  const sectionThree = {
    head: "Work Experience - Alternative Dates",
    error: error,
    subhead:
      "We're delighted that you're able to offer work experience to our associates. We're keen to find out more about the opportunities you can provide and when you may be able to provide them.",
    work: work,
    workExperienceAreas: {
      header:
        "Which area are you able to provide work experience in?  Please select the area that best aligns to the work experience placement you are able to offer, this may differ from your organisations primary sector. If this is not yet know please move on to the next question.",
      opt: workAreas,
    },
    workExperienceAreasSecondary: {
      header:
        "If you are able to provide work experience in more than one area please select the secondary area you're able to offer a placement in.",
      opt: workAreas,
    },
    location: {
      text:
        "In which location(s) are you able to offer placements? (We are keen to offer placements across the country so welcome opportunities in London but also outside of London and the South East)",
      place: locations,
    },
    placements: {
      text: "Please confirm how many placements you are able to offer ",
      range: placements,
    },
    donation: {
      text:
        "We're delighted that you're able to offer work experience to our associates between 10th-14th July 2022. We're keen to find out more about the opportunities you can provide.",
      img: img1,
    },
    donationSecondary: {
      text:
        "​​Please confirm which of the above suggested donation options you are able to offer.",
      options: donations,
    },
    comment: {
      text: "If you have any comments or questions please include them here.",
    },
  };
  const sectionFour = {
    head: "Work Experience - Register Interest",
    error: error,
    subhead:
      "We're delighted that you're thinking of offering work experience to our associates. We're keen to find out more about the opportunities you may be able to provide. ",
    work: work,
    workExperienceAreas: {
      header:
        "Which area are you likely to be able to provide work experience in?  Please select the area that best aligns to the work experience placement you are able to offer, this may differ from your organisations primary sector.",
      opt: workAreas,
    },
    workExperienceAreasSecondary: {
      header:
        "If you are likely to be able to provide work experience  in more than one area please select the secondary area you're able to offer a placement in.",
      opt: workAreas,
    },
    location: {
      text:
        "In which location(s) might you be able to offer placements? (We are keen to offer placements across the country so welcome opportunities in London but also outside of London and the South East) ",
      place: locations,
    },
    placements: {
      text: "How many placements do you think you may be able to offer?",
      range: placements,
    },
    donation: {
      text:
        "Suggested Donation - As part of supporting our aims as a charity we ask that organisations make a donation to support the delivery of our work experience programmes.",
      img: img1,
    },
    donationSecondary: {
      text:
        "​​Please confirm which of the above suggested donation options you are able to offer.",
      options: donations,
    },
    comment: {
      text: "If you have any comments or questions please include them here.",
    },
  };

  var sectors = [
    "Banking and Finance ",
    "Charity and Social Impact ",
    "Creative Industries ",
    "Engineering ",
    "Government and Public Sector ",
    "Investment Management ",
    "Law",
    " Retail and Consumer Goods",
    "Logistics, Transportation and Manufacturing",
    "Science and Technology ",
    " Other ",
  ];

  const experiences = [
    "Yes, we're definitely able to offer work experience placements from 10th-14th July 2023",
    "Yes, we're definitely able to offer work experience placements but for a different week",
    "We may be able to offer placements and want to register our interest",
  ];

  return (
    <StyledWrapper>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {state ? (
        <div className="response-box">
          <img className="response-logo img-fluid" src={img2} />
          <h1 className="response-header">Thank you for your response</h1>
          <p className="response-text text-muted text-center">
            The support from employers is invaluable to our associates and 
            professional work experience helps them with their future careers. 
            We will be in touch shortly to discuss your responses.
          </p>
        </div>
      ) : (
        <div className="container">
          <Form className="form-box" onSubmit={handleSubmit(submitForm)}>
            <img className="img-logo img-fluid" src={img2} />
            <p className="text-justify">
              <h4>WHY ?</h4>
              {/* <br /> */}
              upReach know that in-person work experience is one of the highest
              impact social mobility initiatives that employers can provide. 92%
              of upReach Associates who completed a Professional Work Experience
              Week and then applied for an internship or graduate role the next
              year were successful. However, most of our Associates have never
              had any professional work experience before joining us, and the
              pandemic has meant very few have been able to have vital in-person
              opportunities.
              <br />
              <br />
              <h4>WHAT?</h4>
              Xperience offers in-person work experience to those who’ve had limited 
              opportunities to undertake work experience. The first week is an 
              upReach-led virtual preparation week to help prepare Associates for 
              making the most of the programme, including various skills workshops 
              and panel discussions. The second week is an in-person work experience 
              week hosted by an employer, designed to give the Associates an insight 
              into the sector and expereince in a professional environment. <br />
              <br />
              <h4>CAN YOU HELP?</h4>
              We’re looking for organisations that can offer one or more work 
              experience placements. The opportunities can be in almost any 
              professional sector and based anywhere in the UK. <br />
              <br />
              If you are able to commit to offering places, or wish to express
              an interest, please complete this form.
            </p>
            <Form.Group className="mb-3 form" controlId="formBasicName">
              <Form.Label className="form-label">
                First Name <span className="required-text"> *</span>
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                className="form-control"
                type="text"
                placeholder="First Name"
                name="firstName"
              />
            </Form.Group>
            <Form.Group className="mb-3 form" controlId="formBasicName">
              <Form.Label className="form-label">
                Last Name <span className="required-text"> *</span>
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                className="form-control"
                type="text"
                placeholder="Last Name"
                name="lastName"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="form-label">
                Email Address<span className="required-text"> *</span>
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                className="form-control"
                type="email"
                placeholder="Email Address"
                name="email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicJobTitle ">
              <Form.Label className="form-label">
                Job Title <span className="required-text"> *</span>{" "}
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                type="text"
                placeholder="Job Title"
                name="jobTitle"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicorganisation">
              <Form.Label className="form-label">
                Organisation <span className="required-text"> *</span>
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                type="text"
                placeholder="Organisation"
                name="organisation"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicSector">
              <Form.Label className="form-label">
                What is the primary sector of your organisation?
                <span className="required-text"> *</span>
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                as="select"
                name="sector"
              >
                <option value="null">Select your sector</option>
                {sectors.map(sector => (
                  <option value={`${sector}`}>{sector}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formWorkExperience">
              <Form.Label className="form-label">
                Is your organisation able to offer work experience to upReach
                associates? <span className="required-text"> *</span>
              </Form.Label>

              <Form.Control
                ref={register({ required: true })}
                as="select"
                name="workExperience"
                onChange={handleOnChange}
              >
                <option value="null">Select your work experience</option>

                {experiences.map(experience => (
                  <option value={`${experience}`}>{experience}</option>
                ))}
              </Form.Control>
            </Form.Group>
            {work ===
            "Yes, we're definitely able to offer work experience placements from 10th-14th July 2023" ? (
              <div>
                <XperienceForm
                  content={sectionTwo}
                  register={register}
                  disableBtn={disableBtn}
                  noOfLocations={setNoOfLocations}
                />
              </div>
            ) : work ===
              "Yes, we're definitely able to offer work experience placements but for a different week" ? (
              <XperienceForm
                content={sectionThree}
                register={register}
                disableBtn={disableBtn}
                noOfLocations={setNoOfLocations}
              />
            ) : work ===
              "We may be able to offer placements and want to register our interest" ? (
              <XperienceForm
                content={sectionFour}
                register={register}
                disableBtn={disableBtn}
                noOfLocations={setNoOfLocations}
              />
            ) : (
              <></>
            )}
          </Form>
        </div>
      )}
    </StyledWrapper>
  );
};

export default ExperienceForm;
