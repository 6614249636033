import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form } from "react-bootstrap";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";

const StyledWrapper = styled.div`
  .donation-img {
    height: 30rem;
    width: 60rem;
    margin: 0 auto;
    @media (max-width: 600px) {
      height: 10rem;
      width: 100%;
    }
  }
  .subtext-1 {
    color: black;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .text-label-upper {
    margin-top: 1rem;
  }
  .text-size-1 {
    font-size: 1.1rem;
    color: black;
    margin-bottom: 1rem;
  }
  .fail-text {
    color: red;
  }
  .text-donation {
    margin-bottom: 0.1rem;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .multiSelect {
    margin-bottom: 1rem;
    width: 90%;
    background: #ececec;
    color: #0a0a0a;
    transform: scaleY(1);
    height: 100%;
  }

}
  .top-margin {
    margin-top: 0.7rem;
  }
  .terms {
    font-size: 1.1rem;
  }
  .text-link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  .text-label-lower {
    margin-bottom: 1rem;
  }
  .msl-input:before {
    line-height: var(--line-height);
    color: #495057;
}
.msl-input, .msl-input-wrp {
    padding-top: 0.5rem;
    padding-bottom:0.5rem;
    padding-left:0.5rem;
}
`;

const XperienceForm = ({ register, content, disableBtn, noOfLocations }) => {
  const [value, setValue] = useState(false);
  const [checkValue, setCheckValue] = useState("");
  const handleCheck = e => {
    const checked = e.target.checked;
    setValue(checked);
  };
  const handleOnChange = e => {
    noOfLocations(e);
  };
  let options = [];
  content.location.place.map(option => {
    options.push({ label: option, value: option });
  });
  return (
    <StyledWrapper>
      <div>
        <h3 className="subtext-1">{content.subhead}</h3>
        <Form.Group className="mb-3" controlId="formBasicWorkExperienceAreas">
          <Form.Label className="form-label">
            {content.workExperienceAreas.header}
          </Form.Label>

          <Form.Control
            ref={register({ required: false })}
            as="select"
            name="workExperienceAreas"
            className="top-margin"
          >
            <option value="null">Select your primary work experience</option>
            {content.workExperienceAreas.opt.map(option => (
              <option value={`${option}`}>{option}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="formWorkExperienceAreasSecondary"
        >
          <Form.Label className="form-label">
            {content.workExperienceAreasSecondary.header}
          </Form.Label>
          <Form.Control
            ref={register({ required: false })}
            as="select"
            name="workExperienceAreasSecondary"
            className="top-margin"
          >
            <option value="null">Select your secondary work experience</option>
            {content.workExperienceAreasSecondary.opt.map(option => (
              <option value={`${option}`}>{option}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicLocation">
          <Form.Label className="form-label">
            {content.location.text} <span className="required-text"> *</span>
          </Form.Label>
        </Form.Group>
        <MultiSelect
          className="multiSelect"
          placeholder="Selection your location"
          name="location"
          onChange={handleOnChange}
          options={options}
        />
        <Form.Group className="mb-3" controlId="formNoOfPlacements">
          <Form.Label className="text-label-lower">
            {content.placements.text}
            <span className="required-text"> *</span>
          </Form.Label>

          <Form.Control
            ref={register({ required: true })}
            as="select"
            name="noOfPlacements"
          >
            <option value="null">Select number of placements</option>
            {content.placements.range.map(option => (
              <option value={`${option}`}>{option}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <spam className="text-donation d-block text-muted text-justify">
        {/* The below code was removed by 1stMain developers to make a single content for all the donation types, if we enable this code we can show dynamic donation type based contents. */}
        {/* {content.donation.text}{" "} */}
          We are asking employers to make a donation to cover 
          participant costs (e.g. professional clothing bursary, reasonable 
          travel and accommodation costs and a completion bursary) and upReach’s 
          costs in delivering the programme. It cost upReach £864 per Associate 
          to deliver the 2022 programme (including all of the bursaries, travel and accommodation). 
          Taking into account inflation we are asking employers to donate the amounts outlined in the table. 
          We appreciate that some organisations (for example charities) may not be able to make the full a donation. 
          Please let us know if this is the case as we may be able to look into other fundraising options 
          in order to offer places without a donation.{" "}
        </spam>
        <img
          className="donation-img img-fluid d-block"
          src={content.donation.img}
        />
        <Form.Group className="mb-3" controlId="formBasicDonationSecondary">
          <Form.Text className="text-label-upper text-donation text-justify">
            {content.donationSecondary.text}{" "}
            <span className="required-text"> *</span>
          </Form.Text>
          <Form.Control
            ref={register({ required: true })}
            as="select"
            name="donationSecondary"
            className="top-margin"
          >
            <option value="null">Select your donation option</option>
            {content.donationSecondary.options.map(option => (
              <option value={`${option}`}>{option}</option>
            ))}
          </Form.Control>
        </Form.Group>
        {content.work ===
        "Yes, we're definitely able to offer work experience placements but for a different week" ? (
          <Form.Group className="mb-3 form" controlId="formBasicDate">
            <Form.Label className="form-label">
              Which dates are you able to offer work experience?
              <span className="required-text"> *</span>
            </Form.Label>

            <Form.Control
              ref={register({ required: true })}
              className="form-control"
              type="text"
              placeholder="Please enter your dates"
              name="date"
            />
          </Form.Group>
        ) : (
          <></>
        )}
        <Form.Group className="mb-3 form" controlId="formBasicCommentsTwo">
          <Form.Label className="form-label">Comments</Form.Label>
          <Form.Control
            ref={register({ required: false })}
            className="form-textarea"
            type="text"
            as="textarea"
            placeholder={`${content.comment.text}`}
            name="comments"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Label className="form-label">
            Terms and Conditions, Values and Code of Conduct
            <span className="required-text"> *</span>
          </Form.Label>
          <Form.Text className="text-muted">
            We really appreciate your support in offering work experience
            places. Please confirm that you are happy to commit to offering the
            places you have outlined in this form and that you agree to our{" "}
            <a
              href=" https://cms.upreach.org.uk/uploads/up_Reach_Terms_and_Conditions_Xperience_by_up_Reach_2023_docx_3356040da1.pdf"
              target="_blank"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://cms.upreach.org.uk/uploads/up_Reach_Values_and_Code_of_Conduct_Work_Experience_2022_e1c98723a0.pdf"
              target="_blank"
            >
              Code of Conduct
            </a>
            <span className="required-text"> *</span>
          </Form.Text>
          <Form.Check
            ref={register({ required: true })}
            name="termsAndConditions"
            type="checkbox"
            onChange={e => setValue(e.target.checked)}
            label="Yes, I agree to the terms and conditions and code of conduct"
          />
        </Form.Group>
        {value === true ? (
          <></>
        ) : checkValue === false ? (
          <p className="text-muted">
            Please agree to our terms and conditions and code of conduct by
            ticking the box above. If you do not wish to do so, please contact
            <spam className="text-link"> Hannah.Boyne@upreach.org.uk </spam> to
            express your interest
          </p>
        ) : (
          <></>
        )}
        {content.error != "" ? (
          <p className="fail-text pt-3">{content.error}</p>
        ) : (
          <></>
        )}

        <Button
          className="submit d-flex justify-content-center"
          variant="primary"
          type="submit"
          disabled={disableBtn}
          onClick={() => {
            setCheckValue(value);
          }}
        >
          Submit
        </Button>
      </div>
    </StyledWrapper>
  );
};
export default XperienceForm;
